import React, { Component } from 'react';
import openclassrooms from './images/openclassrooms.jpg';
import udacity from './images/udacity.png';
import Header from './header.js';
import Hero from './hero.js';


class About extends Component {
  render() {
    return (
      <>
        <Header />
        <Hero title="About" subtitle="Hello! My name is Nathaniel and I am a Full Stack Developer." />

        <section className="section">
          <div className="container">
            <h2 className="title is-2 is-lowercase">Education</h2>
            <article className="media">
              <figure className="media-left">
                <p className="image is-96x96">
                  <img src={openclassrooms} alt="OpenClassrooms logo" />
                </p>
              </figure>
              <div className="media-content">
                <div className="content">
                  <p>
                    <strong>Software Developer Diploma (Développeur·se d’application), OpenClassrooms</strong><br />
                    •	Level 6 Bachelor's-level Diploma on the European Qualifications Framework (EQF)<br />
                    •	Full-Stack Developer Path with 9 hands-on projects covering React, Ruby on Rails, Ruby, SQL, UML, JavaScript, CSS, HTML, Bootstrap, Heroku, phpMyAdmin, WordPress, and unit, integration, and system testing<br />
                    <small className="has-text-grey">Nov 2018 – Apr 2019</small>
                  </p>
                </div>
              </div>
            </article>

            <article className="media">
              <figure className="media-left">
                <p className="image is-96x96">
                  <img src={udacity} alt="Udacity logo" />
                </p>
              </figure>
              <div className="media-content">
                <div className="content">
                  <p>
                    <strong>Front-End Web Developer Nanodegree Program, Udacity</strong><br />
                    •	Built 7 hands-on projects with lessons covering JavaScript, CSS, HTML, Git, Bootstrap, Angular, Ember, KnockoutJS, jQuery, Jasmine testing, and various APIs<br />
                    <small className="has-text-grey">Aug 2017 – Nov 2017</small>
                  </p>
                </div>
              </div>
            </article>
          </div>
        </section>

        <section className="section">
          <div className="container">
            <h2 className="title is-2 is-lowercase">Skills</h2>
            <p className="subtitle is-4 has-text-grey">I am an advocate of lifelong learning.</p>
            <div className="columns">
              <div className="column">
                <p className="heading">LANGUAGES</p>
                <div className="tags are-medium">
                  <span className="tag is-light">JavaScript</span>
                  <span className="tag is-light">CSS</span>
                  <span className="tag is-light">HTML</span>
                  <span className="tag is-light">Ruby</span>
                  <span className="tag is-light">SQL</span>
                  <span className="tag is-light">UML</span>
                </div>
              </div>

              <div className="column">
                <p className="heading">LIBRARIES & FRAMEWORKS</p>
                <div className="tags are-medium">
                  <span className="tag is-light">React</span>
                  <span className="tag is-light">React Router</span>
                  <span className="tag is-light">Ruby on Rails</span>
                  <span className="tag is-light">KnockoutJS</span>
                  <span className="tag is-light">jQuery</span>
                  <span className="tag is-light">Jasmine</span>
                  <span className="tag is-light">Bootstrap</span>
                  <span className="tag is-light">Bulma</span>
                </div>
              </div>

              <div className="column">
                <p className="heading">TOOLS & CMS</p>
                <div className="tags are-medium">
                  <span className="tag is-light">Git</span>
                  <span className="tag is-light">Firebase</span>
                  <span className="tag is-light">Heroku</span>
                  <span className="tag is-light">phpMyAdmin</span>
                  <span className="tag is-light">Knack</span>
                  <span className="tag is-light">ImageJ</span>
                  <span className="tag is-light">WordPress</span>
                  <span className="tag is-light">Squarespace</span>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default About;

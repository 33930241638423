import React, { Component } from 'react';
import img_sisonreview from './images/screenshot-sisonreview-980x735.png';
import img_onlineclassroom from './images/screenshot-onlineclassroom-980x735.png';
import img_neighborhoodaid from './images/screenshot-neighborhoodaid-1024x768.png';
import img_visualraffle from './images/screenshot-visualraffle-576x432.png';
import Header from './header.js';
import Hero from './hero.js';


class Portfolio extends Component {
  render() {
    return (
      <>
        <Header />
        <Hero title="Portfolio" subtitle="Explore some of my featured work." />
        <section id="portfolio" className="section">
          <div className="container">
            <div className="columns is-multiline">
              <div className="column is-half">
                <div className="card">
                  <div className="card-image">
                    <figure className="image">
                      <a href="https://neighborhood-aid.herokuapp.com/" target="_blank" rel="noreferrer noopener">
                        <img src={img_neighborhoodaid} alt="Neighborhood Aid website screenshot" />
                      </a>
                    </figure>
                  </div>
                  <div className="card-content">
                    <h3 className="title is-4 mb-03">
                      Neighborhood Aid
                      <span className="icon ml-03 is-medium">
                        <a href="https://neighborhood-aid.herokuapp.com/" target="_blank" rel="noreferrer noopener">
                          <i className="fas fa-external-link-alt has-text-grey"></i>
                        </a>
                      </span>
                    </h3>
                    <div className="tags">
                      <span className="tag is-light">React</span>
                      <span className="tag is-light">Ruby on Rails</span>
                      <span className="tag is-light">Google Maps API</span>
                    </div>
                    <div className="content">
                      <p>Created aid platform that connects people in need to willing volunteers in the community</p>
                      <p>Users can create and fulfill help requests, message other users, and keep track of all their requests and fulfills</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column is-half">
                <div className="card">
                  <div className="card-image">
                    <a href="https://visual-raffle.com/" target="_blank" rel="noreferrer noopener">
                      <figure className="image">
                        <img src={img_visualraffle} alt="Visual Raffle website screenshot" />
                      </figure>
                    </a>
                  </div>
                  <div className="card-content">
                    <h3 className="title is-4 mb-03">
                      Visual Raffle
                      <span className="icon ml-03 is-medium">
                        <a href="https://visual-raffle.com/" target="_blank" rel="noreferrer noopener">
                          <i className="fas fa-external-link-alt has-text-grey"></i>
                        </a>
                      </span>
                    </h3>
                    <div className="tags">
                      <span className="tag is-light">React</span>
                      <span className="tag is-light">Firebase</span>
                    </div>
                    <div className="content">
                      <p>Created a free online and electronic raffle visualizer that makes social media giveaways more transparent and fun</p>
                      <p>Users can enter raffle entries, see real-time shuffling, and witness a countdown before a winner is chosen</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="column is-half">
                <div className="card">
                  <div className="card-image">
                    <a href="https://sisonreview.com/" target="_blank" rel="noreferrer noopener">
                      <figure className="image">
                        <img src={img_sisonreview} alt="Sison Review Center website screenshot" />
                      </figure>
                    </a>
                  </div>
                  <div className="card-content">
                    <h3 className="title is-4 mb-03">
                      Sison Review Center
                      <span className="icon ml-03 is-medium">
                        <a href="https://sisonreview.com/" target="_blank" rel="noreferrer noopener">
                          <i className="fas fa-external-link-alt has-text-grey"></i>
                        </a>
                      </span>
                    </h3>
                    <div className="tags">
                      <span className="tag is-light">WordPress</span>
                      <span className="tag is-light">Knack</span>
                    </div>
                    <div className="content">
                      <p>Created web app where students can login and see their detailed exam performance and review progress, and advisers can monitor students' performance</p>
                      <p>Developed student enrollment system integrating offline bank payments with online slot confirmations</p>
                      <p>Built the entire website where students can learn more about available review programs and enroll in classes</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column is-half">
                <div className="card">
                  <div className="card-image">
                    <a href="https://classroom.sisonreview.com/" target="_blank" rel="noreferrer noopener">
                      <figure className="image">
                        <img src={img_onlineclassroom} alt="SRC Online Classroom website screenshot" />
                      </figure>
                    </a>
                  </div>
                  <div className="card-content">
                    <h3 className="title is-4 mb-03">
                      SRC Online Classroom
                      <span className="icon ml-03 is-medium">
                        <a href="https://classroom.sisonreview.com/" target="_blank" rel="noreferrer noopener">
                          <i className="fas fa-external-link-alt has-text-grey"></i>
                        </a>
                      </span>
                    </h3>
                    <div className="tags">
                      <span className="tag is-light">WordPress</span>
                      <span className="tag is-light">LearnDash</span>
                    </div>
                    <div className="content">
                      <p>Created an industry-first learning platform for Chemical Technicians and Chemists in the Philippines featuring video lessons and quizzes</p>
                      <p>Developed the entire program architecture from content delivery and design, to enrollment and payments</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Portfolio;

import React, { Component } from 'react';
import Header from './header.js';
import Hero from './hero.js';


class Contact extends Component {
  render() {
    return (
      <>
        <Header />
        <Hero title="Contact" subtitle="Let's continue the conversation." />
        <section id="contact" className="section">
          <div className="container">

            <nav className="level">
              <div className="level-left">

                <a className="button is-rounded level-item" href="https://twitter.com/nateydev" target="_blank" rel="noreferrer noopener">
                  <span className="icon">
                    <i className="fab fa-twitter"></i>
                  </span>
                  <span>@nateydev</span>
                </a>

                <a className="button is-rounded level-item" href="mailto:nathaniel@nathanielcarolina.com">
                  <span className="icon">
                    <i className="fas fa-envelope"></i>
                  </span>
                  <span>Email</span>
                </a>

                <a className="button is-rounded level-item" href="https://www.linkedin.com/in/nathanielcarolina" target="_blank" rel="noreferrer noopener">
                  <span className="icon">
                    <i className="fab fa-linkedin"></i>
                  </span>
                  <span>LinkedIn</span>
                </a>

                <a className="button is-rounded level-item" href="https://medium.com/@nathanielcarolina" target="_blank" rel="noreferrer noopener">
                  <span className="icon">
                    <i className="fab fa-medium"></i>
                  </span>
                  <span>Medium</span>
                </a>

                <a className="button is-rounded level-item" href="https://github.com/nathanielcarolina" target="_blank" rel="noreferrer noopener">
                  <span className="icon">
                    <i className="fab fa-github"></i>
                  </span>
                  <span>GitHub</span>
                </a>

              </div>
            </nav>
          </div>
        </section>
      </>
    );
  }
}

export default Contact;

import React, { Component } from 'react';
import { NavHashLink as NavLink } from 'react-router-hash-link';


class Header extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isActive: false
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      isActive: !this.state.isActive
    });
  }

  render() {
    return (
      <nav className="navbar is-light is-fixed-top" role="navigation" aria-label="main navigation">
        <div className="container">
          <div className="navbar-brand">
            <NavLink
              className="navbar-item"
              to="/"
              onClick={this.state.isActive ? this.toggle : null}
            >
              <h1 className="title is-4 is-lowercase">Nathaniel Carolina</h1>
            </NavLink>

            <div role="button" className={this.state.isActive ? "navbar-burger burger is-active" : "navbar-burger burger"} onClick={this.toggle} aria-label="menu" aria-expanded="false" data-target="navbarRight">
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </div>
          </div>

          <div id="navbarRight" className={this.state.isActive ? 'navbar-menu is-active' : 'navbar-menu'}>

            <div className="navbar-end">

              <NavLink
                className="navbar-item is-lowercase has-text-dark"
                to="/portfolio"
                activeClassName="is-active"
                onClick={this.state.isActive ? this.toggle : null}
              >
                Portfolio
              </NavLink>

              <NavLink
                className="navbar-item is-lowercase has-text-dark"
                to="/about"
                activeClassName="is-active"
                onClick={this.state.isActive ? this.toggle : null}
              >
                About
              </NavLink>

              <NavLink
                className="navbar-item is-lowercase has-text-dark"
                to="/contact"
                activeClassName="is-active"
                onClick={this.state.isActive ? this.toggle : null}
              >
                Contact
              </NavLink>

            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default Header;

import React, { Component } from 'react';


class Hero extends Component {
  render() {
    return (
      <section className="hero pt-52">
        <div className="hero-body">
          <div className="container">
            <h2 className="title is-2 is-lowercase">
              {this.props.title}
            </h2>
            <h3 className="subtitle is-4 has-text-grey">
              {this.props.subtitle}
            </h3>
          </div>
        </div>
      </section>
    );
  }
}

export default Hero;

import React, { Component } from 'react';
import { Switch, Route } from "react-router-dom";
import Home from './home.js';
import About from './about.js';
import Contact from './contact.js';
import Portfolio from './portfolio.js';


class Router extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/portfolio" component={Portfolio} />
        <Route path="/about" component={About} />
        <Route path="/contact" component={Contact} />
      </Switch>
    );
  }
}

export default Router;

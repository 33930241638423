import React, { Component } from 'react';
import { Link } from "react-router-dom";


class Home extends Component {
  render() {
    return (
      <div>
        <section className="hero is-fullheight">
          <div className="hero-body">
            <div className="container">
              <h1 className="title is-1 is-lowercase">Nathaniel Carolina</h1>
              <h2 className="subtitle is-3 is-uppercase has-text-grey">Full Stack Developer</h2>
              <nav className="level">
                <div className="level-left">
                  <Link to="/portfolio" className="button is-rounded is-lowercase level-item">Portfolio</Link>
                  <Link to="/about" className="button is-rounded is-lowercase level-item">About</Link>
                  <Link to="/contact" className="button is-rounded is-lowercase level-item">Contact</Link>
                </div>
              </nav>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Home;
